import tw, { styled } from "twin.macro"
import { RichText } from "../RichText/RichText"
import { StyledContainer } from "../Styled/Container"
import { TabList, Tab } from "@reach/tabs"
import { Table } from "../Table/Table"

export const Wrapper = styled(StyledContainer)`
  ${tw`py-3-2 md:py-8 max-w-99`}
`

export const Title = tw.h1`
  text-20 leading-1.5 tracking-10 md:text-35 md:leading-1.28 text-center font-bold uppercase mb-1-6
`

export const Description = styled(RichText)`
  ${tw`max-w-63 mx-auto mb-3-2 md:mb-8`}
`

export const StyledTabList = styled(TabList)`
  ${tw`flex flex-row mb-3-2 md:mb-0`}
`

export const StyledTab = styled(Tab)`
  ${tw`border border-grey-lightest w-full py-1-6`}
  ${({ isSelected }) =>
    isSelected ? tw`bg-navy text-white` : tw`bg-white text-navy`}
`

export const CategoryWrapper = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-3 md:px-4 py-3 border-t border-navy md:border-t-0`}
`

export const CategoryTitle = tw.h2`
  col-span-1 text-16 leading-1.2 tracking-10 font-bold uppercase mb-2-4 max-w-22
`

export const TablesWrapper = tw.div`
  col-span-2 
`

export const Tables = tw.div`
  flex flex-col md:flex-row
`

export const StyledTable = styled(Table)`
  ${tw`md:mr-3 mb-3-2 md:mb-0`}
  :last-of-type {
    ${tw`mr-0 mb-0`}
  }
`

export const ImageWrapper = tw.div`
  border border-grey-lightest mt-1-6 md:mt-2-4
`

export const BorderWrapper = tw.div`
  md:border border-grey-lightest md:py-1
`
