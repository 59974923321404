import React from "react"
import { graphql } from "gatsby"

import { SizeGuide as Page } from "../components/SizeGuide/SizeGuide"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageSizeGuide {
      title
      content: _rawDescription(resolveReferences: { maxDepth: 2 })
      tabs: _rawTabs(resolveReferences: { maxDepth: 6 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("sizeguide_page")


  return <Page {...props} {...data} />
}
export default Component
